import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Text = makeShortcode("Text");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <Text sx={{
      fontSize: [4, 5, 6],
      fontWeight: `bold`,
      color: `heading`
    }} mdxType="Text">
  Hey there!
    </Text>
    <p>{`I'm Saurabh - a Software Engineer by profession and a problem solver at heart.`}</p>
    <p>{`My passion is solving problems with `}<em parentName="p">{`Computer Science`}</em>{` concepts, my main tool is `}<a target="_blank" rel="noreferrer" href="https://nodejs.org/">{`Node.js`}</a>{` and I love the `}<strong parentName="p">{`power`}</strong>{` and `}<strong parentName="p">{`simplicity`}</strong>{` it provides to develop robust applications.`}</p>
    <p>{`I'm also an avid `}<a target="_blank" rel="noreferrer" href="https://opensource.com/open-source-way">{`OSS`}</a>{` supporter and I appreciate the hard work of the people contributing to it.`}</p>
    <p>{`To learn more about me and how I am playing my part in this universe, you can go `}<a parentName="p" {...{
        "href": "/about"
      }}><strong parentName="a">{`here`}</strong></a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      